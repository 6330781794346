import { useUserLogin } from "@igloo-be-omnipartners/hooks";
import { navigate } from "@reach/router";
import {
  Alert,
  Button,
  colors,
  ffdinPro,
  Fieldset,
  fontFamily,
  Form,
  Input,
  Link,
  Media,
  mediaQuery,
  NewUserLink,
  useInputProps,
} from "@royalcanin-fr-loyalty/ui-kit";
import { FORM_ERROR } from "final-form";
import React from "react";
import styled from "styled-components";
import { object, string } from "yup";
import { useFetchUserRole } from "../../lib/hooks/useUserRole";
import { useFetchUserRoles } from "../../lib/hooks/useUserRoles";
import { UserRoleDefinitionByReferer_userRoleDefinitionByReferer } from "../../lib/hooks/__generated__/UserRoleDefinitionByReferer";
import { TargetBlankHelper } from "@royalcanin-fr-loyalty/ui-kit/src/TargetBlankHelper";

const H1 = styled.h1`
  ${fontFamily(ffdinPro.condensedMedium)}
  color: ${colors.darkDk};
  margin-bottom: 15px;
  text-align: center;
`;

const formatError = (error: { code: string; message: string }) => {
  if (
    error.code === "OP/OPStatusError/3" ||
    error.code === "OP/OPStatusError/5" ||
    error.code === "OP/OPStatusError/17" ||
    error.code === "OP/OPStatusError/28"
  ) {
    return "Identifiant ou mot de passe incorrect.";
  } else if (error.code === "E_NOT_AUTHORIZED") {
    return `${error.message}`;
  } else {
    return `${error.code} - ${error.message}`;
  }
};

export const LoginForm = ({
  roleDefinition,
  error: providedError,
  loading: externalLoading,
  backUrl,
  partnerExtId,
  externalLoginUrl,
}: {
  roleDefinition?: UserRoleDefinitionByReferer_userRoleDefinitionByReferer | null;
  error?: any;
  loading?: boolean;
  backUrl?: string;
  partnerExtId?: string;
  externalLoginUrl?: string;
}) => {
  const fetchUserRole = useFetchUserRole();
  const fetchUserRoles = useFetchUserRoles();
  const { userLogin, loading } = useUserLogin({
    beforeSetToken: async ({ token }) => {
      await Promise.all([
        fetchUserRole({
          token,
        }),
        fetchUserRoles({
          token,
        }),
      ]);
    },
  });

  if (externalLoginUrl) {
    return (
      <Container>
        {providedError && providedError.message && (
          <AlertContainer>
            <Alert type="error">{providedError.message}</Alert>
          </AlertContainer>
        )}
        <H1>Plateforme fidélité</H1>
        <Fieldset offset={providedError && providedError.message ? 40 : 0}>
          <Button fullwidth href={externalLoginUrl}>
            Se connecter
          </Button>
        </Fieldset>
      </Container>
    );
  }

  return (
    <>
      <Form
        loading={loading || externalLoading}
        initialValues={{
          identifier: "",
          password: "",
        }}
        fieldLabels={{
          identifier: "Identifiant",
          password: "Mot de passe",
        }}
        schema={object().shape({
          identifier: string().required(),
          password: string().required(),
        })}
        onSubmit={async ({ identifier, password }) => {
          const { error, result } = await userLogin({
            identifier,
            password,
            userContextData: {
              roleDefinitionId: roleDefinition && roleDefinition.id,
              partnerExtId,
            },
          });

          if (error) {
            return {
              [FORM_ERROR]: formatError(error),
            };
          }

          if (result) {
            if (backUrl) {
              navigate(backUrl);
            } else {
              navigate("/points");
            }
          }

          return;
        }}
        render={({ handleSubmit, submitting, pristine, submitError }) => (
          <Container>
            <form onSubmit={handleSubmit}>
              {submitError && (
                <AlertContainer>
                  <Alert type="error">{submitError}</Alert>
                </AlertContainer>
              )}
              {providedError && providedError.message && (
                <AlertContainer>
                  <Alert type="error">{providedError.message}</Alert>
                </AlertContainer>
              )}
              <Input
                {...useInputProps({ name: "identifier" })}
                type="email"
                fullWidth
              />
              <Fieldset>
                <Input
                  {...useInputProps({ name: "password" })}
                  type="password"
                  fullWidth
                />
              </Fieldset>
              <Fieldset offset={40}>
                <Button
                  type="submit"
                  disabled={submitting || pristine}
                  fullwidth
                >
                  Se connecter
                </Button>
              </Fieldset>
              {roleDefinition && roleDefinition.recoverPasswordLink && (
                <ForgetLink>
                  <Fieldset offset={40}>
                    <Link
                      target="_blank"
                      href={roleDefinition.recoverPasswordLink || ""}
                    >
                      Mot de passe oublié ?
                      <TargetBlankHelper />
                    </Link>
                  </Fieldset>
                </ForgetLink>
              )}
              <Media when={"<=768"}>
                {roleDefinition && roleDefinition.registerLink && (
                  <UserLink>
                    <NewUserLink
                      target="_blank"
                      href={roleDefinition.registerLink || ""}
                    />
                  </UserLink>
                )}
              </Media>
            </form>
          </Container>
        )}
      />
    </>
  );
};

const Container = styled.div`
  flex: 1;
  margin-top: 60px;

  ${mediaQuery("mobile")`
    margin-top: 30px;
  `}

  ${mediaQuery("desktop")`
    margin-top: 150px;
  `}
`;

const ForgetLink = styled.div`
  text-align: center;
`;

const AlertContainer = styled.div`
  margin-bottom: 30px;
`;

const UserLink = styled.div`
  margin-top: auto;
  padding-top: 40px;
  text-align: center;
`;
